.Pointer {
    cursor: pointer;
}

.overflow{
    white-space: nowrap;
}

.table-responsive {
    max-height: 80vh;
}

@media (min-width: 576px) {
    .modal-dialog {
        --bs-modal-width: auto;
    }
}